import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/ContentCreators/ContentCreators.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () =>
      import(
        /* webpackChunkName: "ticketing" */ '../views/Ticketing/Ticketing.vue'
      ),
  },

  // ````````````````````````Features````````````````````````

  {
    path: '/recurringpayments',
    name: 'RecurringPayments',
    component: () =>
      import(
        /* webpackChunkName: "recurringpayments" */ '../views/RecurringPayments/RecurringPayments.vue'
      ),
  },
  {
    path: '/storefronts',
    name: 'NoCodeStorefronts',
    component: () =>
      import(
        /* webpackChunkName: "storefronts" */ '../views/Storefronts/StoreFronts.vue'
      ),
  },
  {
    path: '/automatednotif',
    name: 'AutomatedNotifications',
    component: () =>
      import(
        /* webpackChunkName: "automatednotif" */ '../views/AutomatedNotif/AutomatedNotif.vue'
      ),
  },
  {
    path: '/apiandintegrations',
    name: 'APIAndIntegrations',
    component: () =>
      import(
        /* webpackChunkName: "apiandintegrations" */ '../views/APIAndIntegrations/APIAndIntegrations.vue'
      ),
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () =>
      import(
        /* webpackChunkName: "analytics" */ '../views/Analytics/Analytics.vue'
      ),
  },

  // ````````````````````````Sectors````````````````````````

  {
    path: '/creators',
    name: 'Creators',
    component: () =>
      import(
        /* webpackChunkName: "creators" */ '../views/Creators/Creators.vue'
      ),
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () =>
      import(/* webpackChunkName: "creators" */ '../views/Subscriptions.vue'),
  },
  {
    path: '/saas',
    name: 'Saas',
    component: () =>
      import(/* webpackChunkName: "saas" */ '../views/Saas/Saas.vue'),
  },
  {
    path: '/ecommerce',
    name: 'Ecommerce',
    component: () =>
      import(
        /* webpackChunkName: "ecommerce" */ '../views/Ecommerce/Ecommerce.vue'
      ),
  },
  {
    path: '/services',
    name: 'Services',
    component: () =>
      import(
        /* webpackChunkName: "services" */ '../views/Services/Services.vue'
      ),
  },

  // ````````````````````````Other Pages````````````````````````

  {
    path: '/pricing',
    name: 'businesstab',
    component: () =>
      import(/* webpackChunkName: "pricing" */ '../views/Pricing/Pricing.vue'),
  },
  {
    path: '/helixmedia',
    name: 'helixmedia',
    component: () =>
      import(
        /* webpackChunkName: "pricing" */ '../views/HelixMedia/HelixMedia.vue'
      ),
  },
  {
    path: '/ticketing',
    name: 'Ticketing',
    component: () =>
      import(
        /* webpackChunkName: "ticketing" */ '../views/Ticketing/Ticketing.vue'
      ),
  },
  {
    path: '/incomecalculator',
    name: 'Income Calculator',
    component: () =>
      import(
        /* webpackChunkName: "incomecalculator" */ '../views/IncomeCalculator/IncomeCalculator.vue'
      ),
  },
  {
    path: '/insights',
    name: 'Insights',
    component: () =>
      import(/* webpackChunkName: "insights" */ '../views/Blog.vue'),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () =>
      import(/* webpackChunkName: "careers" */ '../views/Careers/Careers.vue'),
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: () =>
      import(/* webpackChunkName: "aboutUs" */ '../views/About/About.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy/PrivacyPolicy.vue'
      ),
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: () =>
      import(
        /* webpackChunkName: "termsAndConditions" */ '../views/TermsAndConditions/TermsAndConditions.vue'
      ),
  },
  {
    path: '/angwalangkwentangpodcast',
    name: 'AngWalangKwentangPodcast',
    component: () =>
      import(
        /* webpackChunkName: "angwalangkwentangpodcast" */ '../views/AngWalangKwentangPodcast/AngWalangKwentangPodcast.vue'
      ),
  },
  {
    path: '/spart',
    name: 'Spart',
    component: () =>
      import(/* webpackChunkName: "spart" */ '../views/Spart/Spart.vue'),
  },
  // {
  //   path: '/launchpad',
  //   name: 'LaunchPad',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "launchpad" */ '../views/LaunchPad/LaunchPad.vue'
  //     ),
  // },
  {
    path: '/kumuclub',
    name: 'KumuClub',
    component: () =>
      import(/* webpackChunkName: "spart" */ '../views/kumu.vue'),
  },
  {
    path: '/merch',
    name: 'Merch',
    component: () =>
      import(/* webpackChunkName: "spart" */ '../views/Merch/Merch.vue'),
  },
  {
    path: '/surge',
    name: 'SurgeError',
    component: () =>
      import(
        /* webpackChunkName: "surgeError" */ '../views/SurgeError/SurgeError.vue'
      ),
  },
  {
    path: '/articles',
    name: 'ArticleList',
    component: () =>
      import(/* webpackChunkName: "articles" */ '../views/Articles/List.vue'),
  },
  {
    path: '/articles/revolutionizes-online-booking-for-visayas-largest-water-parks',
    name: 'VisayasWaterParks',
    component: () =>
      import(
        /* webpackChunkName: "revolutionizes-online-booking-for-visayas-largest-water-parks" */ '../views/Articles/posts/VisayasWaterParks.vue'
      ),
  },
  {
    path: '/articles/helix-pay-enhances-events-through-on-demand-id-badges-a-game-changer-for-organizers',
    name: 'OnDemandIdBadges',
    component: () =>
      import(
        /* webpackChunkName: "helix-pay-enhances-events-through-on-demand-id-badges-a-game-changer-for-organizers" */ '../views/Articles/posts/OnDemandIdBadges.vue'
      ),
  },
  {
    path: '/articles/ticketing-for-inclusivity-helixpays-support-of-lgbtqia-events',
    name: 'TicketingForInclusivity',
    component: () =>
      import(
        /* webpackChunkName: "ticketing-for-inclusivity-helixpays-support-of-lgbtqia-events" */ '../views/Articles/posts/TicketingForInclusivity.vue'
      ),
  },
  {
    path: '/articles/helix-pay-partners-with-barefoot-theatre-collaborative-for-bar-boys-final-week-success',
    name: 'BarBoys',
    component: () =>
      import(
        /* webpackChunkName: "helix-pay-partners-with-barefoot-theatre-collaborative-for-bar-boys-final-week-success" */ '../views/Articles/posts/BarBoys.vue'
      ),
  },
  {
    path: '/articles/up-sports-ticketing',
    name: 'UpSports',
    component: () =>
      import(
        /* webpackChunkName: "up-sports-ticketing" */ '../views/Articles/posts/UpSports.vue'
      ),
  },
  {
    path: '/articles/dfesta',
    name: 'UpSports',
    component: () =>
      import(
        /* webpackChunkName: "dfesta" */ '../views/Articles/posts/DFesta.vue'
      ),
  },
];

const router = createRouter({
  scrollBehavior() {
    return {
      top: 0,
    };
  },

  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
