<template>
  <div>
    <AnnouncementHeader v-if="route.name !== 'SurgeError'" />
    <router-view />
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import AnnouncementHeader from './components/AnnouncementHeader.vue';

const meta = {
  title: 'HelixPay | Event Ticketing Technology for the Philippines',
  description:
    'HelixPay provides world-class event ticketing solutions for large events in the Philippines with online and on-site payment services, digital tickets, and custom event websites.',
};

const route = useRoute();

useHead({
  title: meta.title,
  meta: [
    {
      name: 'title',
      content: meta.title,
    },
    {
      name: 'description',
      content: meta.description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:title',
      content: meta.title,
    },
    {
      property: 'og:description',
      content: meta.description,
    },
    {
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'twitter:title',
      content: meta.title,
    },
    {
      property: 'twitter:description',
      content: meta.description,
    },
  ],
});
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
